import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IGroup, IGroupDTO, IGroupListItem, IGroupListItemDTO } from '../models/group.interface';
import { IResults } from '../models/results.interface';
import { IUser } from '../models/user.interface';
import { ProfileState } from '../store/profile/profile.state';
import { ApiClientService } from './api.service';
import { SchoolYearsService } from './dictionary/school-years.service';
import { StudentsParserService } from './students.parser';

@Injectable({
    providedIn: 'root',
})
export class GroupsService {
    private _user: IUser;

    constructor(
        protected api: ApiClientService,
        private studentsParserService: StudentsParserService,
        public schoolYearsService: SchoolYearsService,
        private store: Store
    ) {}

    public getPath(): string {
        return `groups`;
    }

    public get(params: { [key: string]: unknown } = {}): Observable<IGroupListItem[]> {
        return this.api.get<IResults<IGroupListItemDTO[]>>(this.getPath() + '/plain', { params }).pipe(map(dtos => this.parseAllListItems(dtos?.results)));
    }

    public getOne(id: number): Observable<IGroup> {
        return this.api.get<IResults<IGroupDTO>>(this.getPath() + '/' + id).pipe(map(dto => this.parse(dto?.results)));
    }

    public changeZoomUrl(id: number, zoomUrl: string): Observable<void> {
        return this.api.patch<void>(this.getPath() + '/' + id + '/zoom', { zoomUrl });
    }

    public changeLector(id: number, data: { lector: number; substituteLector?: number }): Observable<void> {
        return this.api.patch<void>(this.getPath() + '/' + id + '/lector', data);
    }

    public changeGroupColor(id: number, data: { color: number }): Observable<void> {
        return this.api.patch<void>(this.getPath() + '/' + id + '/color', data);
    }

    public parseAllListItems(dtos: IGroupListItemDTO[]): IGroupListItem[] {
        if (!dtos) {
            return null;
        }

        this._user = this.store.selectSnapshot(ProfileState.user);

        return dtos.map(dto => this.parseListItem(dto));
    }

    public parse(dto: IGroupDTO): IGroup {
        if (!dto) {
            return null;
        }

        if (dto._nearestSchedule) {
            Object.assign(dto, {
                ...dto._nearestSchedule,
            });
        }

        const group: IGroup = {
            id: dto.id,
            _agreementCity: dto._agreementCity,
            agency: dto.agency,
            color: dto.color,
            firstDayLocalization: dto.firstDayLocalization,
            firstDayOfWeek: dto.firstDayOfWeek,
            firstDayRoom: dto.firstDayRoom,
            isFirstDayCustomTime: dto.isFirstDayCustomTime,
            fullName: dto.fullName,
            isDraft: dto.isDraft,
            isFirstDayOnline: dto.isFirstDayOnline,
            isSecondDayOnline: dto.isSecondDayOnline,
            lector: dto.lector,
            lessonDays: dto.lessonDays,
            // media: dto.media,
            name: dto.name,
            secondDayLocalization: dto.secondDayLocalization,
            secondDayOfWeek: dto.secondDayOfWeek,
            secondDayRoom: dto.secondDayRoom,
            isSecondDayCustomTime: dto.isSecondDayCustomTime,
            startedLessonDays: dto.startedLessonDays,
            substituteLector: dto.substituteLector,
            useGrades: dto.useGrades,
            zoomUrl: dto.zoomUrl,
            // firstUnit: dto.firstUnit,
            realization: dto.realization,
            level: dto.level,
            realizationLanguageLevel: dto.realizationLanguageLevel,
            workshopLessonsCnt: dto.workshopLessonsCnt,

            startedAt: dto.startedAt,
            finishedAt: dto.finishedAt,

            firstDayStartHour: dto.firstDayStartHour,
            firstDayFinishHour: dto.firstDayFinishHour,

            secondDayStartHour: dto.secondDayStartHour,
            secondDayFinishHour: dto.secondDayFinishHour,
            students: dto.students
                ? dto.students
                      .map(u => this.studentsParserService.parse(u))
                      .sort((a, b) => {
                          return a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName) || a.id - b.id;
                      })
                : null,
            schoolYear: dto.schoolYear,

            parsedZoomUrl: dto.zoomUrl ? dto.zoomUrl.replace('https://', '') : undefined,
            _productStatus: dto._productStatus,
            _agreementValidation: dto._agreementValidation,
        };

        this._user = this.store.selectSnapshot(ProfileState.user);

        if (dto.franchise) {
            group.franchise = dto.franchise;
        }

        if (this._user && dto.substituteLector && this._user.id === dto.substituteLector.id) {
            group.color.background = '#7510C7';
        }

        if (dto.schoolYearSettings) {
            group.schoolYearSettings = {
                finishFirstSemesterDate: dto.schoolYearSettings.finishFirstSemesterDate,
                startSecondSemesterDate: dto.schoolYearSettings.startSecondSemesterDate,
            };
        }

        for (const student of group.students) {
            for (const studentSchoolYear of student.studentSchoolYears) {
                if (studentSchoolYear.schoolYear.id === group.schoolYear?.id) {
                    studentSchoolYear.group = {
                        id: group.id,
                        _agreementCity: group._agreementCity,
                        fullName: group.fullName,
                        isDraft: group.isDraft,
                        name: group.name,
                        levelHierarchy: group.level?.hierarchyNumber,
                        studentCount: group.students?.length,
                    };
                }
            }
        }

        return group;
    }

    private parseListItem(dto: IGroupListItemDTO): IGroupListItem {
        if (!dto) {
            return null;
        }

        if (dto._nearestSchedule) {
            Object.assign(dto, {
                ...dto._nearestSchedule,
            });
        }

        const group: IGroupListItem = {
            ...dto,
            firstDayStartHour: dto.firstDayStartHour,
            firstDayFinishHour: dto.firstDayFinishHour,

            secondDayStartHour: dto.secondDayStartHour,
            secondDayFinishHour: dto.secondDayFinishHour,
            levelName: dto.fullName.replace(' ' + dto.name, ''),
        };

        if (this._user && dto.substituteLector && this._user.id === dto.substituteLector.id) {
            group.color = {
                background: '#7510C7',
                id: 0,
                hierarchyNumber: 0,
            };
        }

        return group;
    }
}
