export class SetSelectedSchoolYear {
    public static readonly type = '[SchoolYearSelector] SetSelectedSchoolYear';
    constructor(public payload: { id: number }) {}
}

export class SetMinimalSchoolYear {
    public static readonly type = '[SchoolYearSelector] SetMinimalSchoolYear';
    constructor(public payload: { id: number }) {}
}

export class SetDisabledSchoolYears {
    public static readonly type = '[SchoolYearSelector] SetDisabledSchoolYears';
    constructor(public payload: { ids: number[] | null; disableTooltip?: string }) {}
}
